import './social-media.scss';
import Swiper, { Navigation } from 'swiper';

import 'swiper/scss';
import 'swiper/scss/navigation';

class SocialMedia {
    constructor ($element, options) {
        this.$socialMedia = $element;
        this.$slider = this.$socialMedia.querySelector('[data-socialmedia="slider"]');
        this.$slidePrev = this.$socialMedia.querySelector('[data-socialmedia="prev"]');
        this.$slideNext = this.$socialMedia.querySelector('[data-socialmedia="next"]');
        this.slider = null;
    }

    initialize () {
        this.initSlider();
    }

    initSlider () {
        if (this.$slider) {
            this.slider = new Swiper(this.$slider, {
                modules: [Navigation],
                slidesPerView: 'auto',
                spaceBetween: 0,
                autoHeight: false,
                speed: 500,
                navigation: {
                    prevEl: this.$slidePrev,
                    nextEl: this.$slideNext
                }
            });

            setTimeout(() => {
                this.slider.update();
            }, 2000);
        }
    }
}

export { SocialMedia };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$socialMedia = $context.querySelectorAll('[data-socialmedia="root"]');

        for (let i = 0; i < $$socialMedia.length; i++) {
            const $socialMedia = new SocialMedia($$socialMedia[i], {
                speed: 500,
                onlyOneActive: true,
                waitUntilClose: false,
                activeClass: 'is--active',
                breakPointMin: 1024
            });
            $socialMedia.initialize();
        }
    }
});
